import { Flex } from 'component-library/components/layout/Flex';
import { colors } from 'component-library/styles/colors';
import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import styled from 'styled-components';
const search = '/search.svg';

const Form = styled.form`
  padding-bottom: 32px;
`;

const Wrapper = styled(Flex)`
  background: ${colors.white};
  border: 1px solid ${colors.grey};
  box-sizing: border-box;
  box-shadow: 0px 1.5px 3px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 8px;
`;

const StyledInput = styled.input`
  outline: none;
  width: 80%;
`;

const MagnifyingGlass = styled.img`
  margin-right: 8px;
`;

export const Input = connectSearchBox(({ refine, ...rest }) => (
  <Form>
    <Wrapper>
      <MagnifyingGlass src={search} alt="Magnifying Glass" />
      <StyledInput
        type="text"
        placeholder="Search Advice"
        aria-label="Search"
        onChange={(e) => refine(e.target.value)}
        {...rest}
      />
    </Wrapper>
  </Form>
));
