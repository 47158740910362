import { Body } from 'component-library/components/typography/Body';
import { formatDate } from 'helpers/formatDate';
import { titleize } from 'helpers/titleize';
import React, { FC } from 'react';

interface BlogPostDetailsProps {
  displayedPublishDate: string;
  updatedDate?: string;
  category?: string | null;
}

export const BlogPostDetails: FC<BlogPostDetailsProps> = ({
  displayedPublishDate,
  updatedDate,
  category,
}) => (
  <Body variant={1}>{`${titleize(category || '')} • Published ${formatDate(
    new Date(displayedPublishDate),
  )} ${
    updatedDate ? `, Updated ${formatDate(new Date(updatedDate))}` : ''
  }`}</Body>
);
