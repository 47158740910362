import { Location } from '@reach/router';
import { Link as GatsbyLink } from 'gatsby';
import React, { FC, PropsWithChildren } from 'react';
import { text } from 'shared/theme';
import styled from 'styled-components';

import { baseLinkStyles } from './shared';

interface StyledLinkProps {
  fontSize?: keyof typeof text;
}

const sharedLinkStyles = (fontSize?: keyof typeof text) => `
  font-size: ${fontSize ? text[fontSize] : text[1]};
  display: block;
  width: fit-content;

  ${baseLinkStyles}
`;

const StyledLink = styled.a<StyledLinkProps>`
  ${({ fontSize }) => sharedLinkStyles(fontSize)}
`;

const StyledInternalLink = styled(GatsbyLink)<StyledLinkProps>`
  ${({ fontSize }) => sharedLinkStyles(fontSize)}
`;

interface LinkProps {
  to: string;
  fontSize?: keyof typeof text;
}

export const Link: FC<PropsWithChildren<LinkProps>> = ({
  to,
  children,
  ...rest
}) => {
  const isExternalLink = to.includes('https') || to.includes('mailto');

  return (
    <Location>
      {({ location }) =>
        isExternalLink ? (
          <StyledLink href={to + location.search} target="_blank" {...rest}>
            {children}
          </StyledLink>
        ) : (
          <StyledInternalLink to={to + location.search} {...rest}>
            {children}
          </StyledInternalLink>
        )
      }
    </Location>
  );
};
