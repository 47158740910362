import { Base } from 'common/layouts/Base';
import { Link } from 'common/links/Link';
import { Heading } from 'component-library/components/typography/Heading';
import { graphql } from 'gatsby';
import {
  ArticleSummaryFragmentFragment as ArticleSummaryFragmentTypes,
  ContentVariablesFragment,
} from 'graphqlTypes';
import { normalizeUrl } from 'helpers/normalizeUrlNew';
import { replaceContentVariables } from 'helpers/replaceContentVariables';
import React, { FC } from 'react';

import { BlogPostDetails } from './BlogPostDetails';

export const ArticleSummaryFragment = graphql`
  fragment ArticleSummaryFragment on ContentfulBlogPost {
    id
    primaryBlogCategory {
      id
      title
    }
    slug
    displayedPublishDate
    updatedAt
    title
  }
`;

interface ArticleSummaryProps {
  article: ArticleSummaryFragmentTypes;
  contentVariables: ContentVariablesFragment[];
}

export const ArticleSummary: FC<ArticleSummaryProps> = ({
  article,
  contentVariables,
}) => (
  <Base spacing={3}>
    <Link
      to={normalizeUrl(
        `/advice/${article.primaryBlogCategory?.title}/${article.slug}`,
      )}
    >
      <Heading variant={5}>
        {replaceContentVariables(article.title, contentVariables)}
      </Heading>
      <BlogPostDetails
        category={article.primaryBlogCategory?.title || ''}
        displayedPublishDate={article.displayedPublishDate}
        updatedDate={article.updatedAt}
      />
    </Link>
  </Base>
);
