import { Flex } from 'component-library/components/layout/Flex';
import { colors } from 'component-library/styles/colors';
import { device } from 'component-library/styles/device';
import React, { FC } from 'react';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

const StyledReactPaginate = styled(Flex)`
  justify-content: center;

  .pagination {
    display: flex;
    border: 1px solid ${colors.grey};
    border-radius: 5px;
  }

  .active {
    font-weight: bold;
    background-color: ${colors.grey};
  }

  li {
    display: flex;
    justify-content: center;
    width: 38px;
    box-sizing: border-box;

    a {
      padding: 8px;
      :focus {
        outline: none;
      }
    }
  }

  li:not(:last-child) {
    border-right: 1px solid ${colors.grey};
  }
`;

const PaginationWrapper = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;

  ${`@media ${device.md}`} {
    flex-direction: row;
  }
`;

interface PaginateProps {
  pageCount: number;
  onPageChange: (selectedItem: { selected: number }) => void;
  currentPage: number;
  perPage: number;
  totalItems: number;
}

export const Paginate: FC<PaginateProps> = ({
  pageCount,
  onPageChange,
  totalItems,
  perPage,
  currentPage,
  ...rest
}) => {
  const start = perPage * currentPage + 1;
  const end = Math.min(perPage * (currentPage + 1), totalItems);

  return (
    <PaginationWrapper {...rest}>
      <div>
        Showing <b>{start}</b> to <b>{end}</b> of <b>{totalItems}</b> results
      </div>
      <StyledReactPaginate>
        <ReactPaginate
          pageCount={pageCount}
          pageRangeDisplayed={0}
          marginPagesDisplayed={1}
          onPageChange={onPageChange}
          previousLabel="←"
          nextLabel="→"
          containerClassName="pagination"
          activeClassName="active"
        />
      </StyledReactPaginate>
    </PaginationWrapper>
  );
};
