import Layout from 'common/layouts/Layout';
import { Navigation } from 'component-library/components/navigation/Navigation';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import { colors } from 'component-library/styles/colors';
import { ArticleSummary } from 'components/pages/advice/ArticleSummary';
import { Paginate } from 'components/paginate/Paginate';
import { SearchWrapper } from 'components/search/SearchWrapper';
import { SEO } from 'components/SEO';
import { graphql } from 'gatsby';
import { AdviceAllQueryQuery } from 'graphqlTypes';
import { BottomCta } from 'page-components/shared';
import { Footer } from 'page-components/shared/footer';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

const StyledPaginate = styled(Paginate)`
  padding: 16px 0 48px;
  border-top: 1px solid ${colors.forestGreen};
`;

interface AllAdviceProps {
  data: AdviceAllQueryQuery;
}

const AllAdvice: FC<AllAdviceProps> = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const PER_PAGE = 10;
  const offset = currentPage * PER_PAGE;
  const currentPageData = data.blogPosts.nodes.slice(offset, offset + PER_PAGE);
  const pageCount = Math.ceil(data.blogPosts.nodes.length / PER_PAGE);

  const handlePageClick = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
  };

  const contentVariables = data.contentVariables.nodes;

  return (
    <Layout>
      <Navigation />
      <PageWrapper background={colors.greenLight}>
        <SearchWrapper contentVariables={contentVariables}>
          {currentPageData.map((article) => (
            <ArticleSummary
              key={article.id}
              article={article}
              contentVariables={contentVariables}
            />
          ))}
          <StyledPaginate
            pageCount={pageCount}
            onPageChange={handlePageClick}
            currentPage={currentPage}
            perPage={PER_PAGE}
            totalItems={data.blogPosts.nodes.length}
          />
        </SearchWrapper>
        <BottomCta
          heading="Start getting your money ready for retirement."
          body="From greater peace of mind to ongoing care, we're ready to lend a hand."
        />
        <Footer />
      </PageWrapper>
    </Layout>
  );
};

export default AllAdvice;

export const query = graphql`
  query AdviceAllQuery {
    blogPosts: allContentfulBlogPost {
      nodes {
        id
        ...ArticleSummaryFragment
      }
    }
    contentVariables: allContentfulContentVariables {
      nodes {
        ...ContentVariables
      }
    }
  }
`;

export const Head = () => (
  <SEO
    title="Retirable FAQs"
    description="Retirable is here to answer your frequently asked questions about retirement."
    imagePath="faq-featured.png"
  />
);
