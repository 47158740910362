import algoliasearch from 'algoliasearch/lite';
import { ArticleSummary } from 'components/pages/advice/ArticleSummary';
import {
  ArticleSummaryFragmentFragment as ArticleSummaryFragmentTypes,
  ContentVariablesFragment,
} from 'graphqlTypes';
import React, { FC, PropsWithChildren, useState } from 'react';
import { StateResultsProvided } from 'react-instantsearch-core';
import { connectStateResults, InstantSearch } from 'react-instantsearch-dom';
import styled from 'styled-components';

import { Input } from './Input';

const Container = styled.div`
  max-width: 1448px;
  padding: 40px;
  margin: 0px auto 40px;
`;

const indices = [
  { name: 'blogPosts', title: 'blogPosts', hitComp: 'blogPosts' },
];

interface ResultsProps extends StateResultsProvided {
  contentVariables: ContentVariablesFragment[];
}
const Results: FC<ResultsProps> = ({
  searchState: state,
  searchResults: res,
  contentVariables,
}) => (
  <>
    {res && res.nbHits > 0
      ? res.hits.map((resp, index) => (
          <ArticleSummary
            article={(resp as unknown) as ArticleSummaryFragmentTypes}
            contentVariables={contentVariables}
            key={index}
          />
        ))
      : `No results for '${state.query}'`}
  </>
);
const ConnectedResults = connectStateResults(Results);

interface SearchWrapperProps {
  contentVariables: ContentVariablesFragment[];
}

export const SearchWrapper: FC<PropsWithChildren<SearchWrapperProps>> = ({
  children,
  contentVariables,
}) => {
  const searchClient = algoliasearch(
    'FWX5G3TQVV',
    'cacf38ca09743de292eeb6b9ff916e75',
  );
  const [query, setQuery] = useState('');

  return (
    <Container>
      <InstantSearch
        searchClient={searchClient}
        indexName={indices[0].name}
        onSearchStateChange={({ query }) => setQuery(query)}
      >
        <Input />
        {query.length > 0 ? (
          <ConnectedResults contentVariables={contentVariables} />
        ) : (
          children
        )}
      </InstantSearch>
    </Container>
  );
};
