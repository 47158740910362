import { graphql } from 'gatsby';
import { ContentVariablesFragment } from 'graphqlTypes';

export const ContentVariables = graphql`
  fragment ContentVariables on ContentfulContentVariables {
    variable
    value
  }
`;

export const replaceContentVariables = (
  text: string | null | undefined,
  contentVariables: ContentVariablesFragment[],
) => {
  if (!text) return text;

  let newText = text;
  contentVariables.forEach(({ variable, value }) => {
    if (variable && value) {
      newText = newText.replace(new RegExp(`{{${variable}}}`, 'g'), value);
    }
  });
  return newText;
};
